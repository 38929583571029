<template>
  <div class="page-view campaign">
    <router-link class="back-to" to="/">
      <v-icon name="fa-chevron-left" />Home
    </router-link>
    <div class="container" v-if="campData">
      <div class="item">
        <h1 class="page-title" v-if="campData.description">—{{campData.description}}</h1>
        <h3 class="subtitle" v-if="campData.bp_name">{{campData.bp_name}}</h3>
        <!-- <h3 class="subtitle">📅&nbsp;{{timeFormatter(campData.start)}}—{{timeFormatter(campData.end)}}</h3> -->
      </div>
      <div class="flex-wrapper bg-primary-lgray mt-8 pb-4">
        <div class="item large">
          <div v-if="loading">
            <v-icon name="fa-spinner" class="animate-spin text-gray-400 w-20" />
          </div>
          <h4 class="end" v-if="!isActive && !loading">
            La campagna non è attiva.<br>
            <small>Non è possibile effettuare ordini in questo momento.</small>
          </h4>
          <div class="new-order" v-if="isActive && !loading">
            <!-- v-else -->
            <!-- STEP 1 -->
            <section class="step step-1" v-show="step === 1">
              <header>
                <h4>
                  Effettua un nuovo ordine
                  <small>
                    #{{(!pastOrders || !pastOrders.length) ? 1 : pastOrders[0].id + 1}}
                    <span v-if="pv" class="text-xs tracking-wide">— P.V. <b class="text-sm">{{pv}}</b>
                      {{getPvName(pv)}}</span>
                  </small>
                </h4>
                <div class="button-group">
                  <button class="ahead" @click="goTo(2)" :class="{disabled : !pv}">
                    Avanti
                    <v-icon name="bi-arrow-right-short" />
                  </button>
                </div>
              </header>
              <div class="table-wrapper overflowed">
                <table>
                  <thead>
                    <tr>
                      <th>Seleziona un Punto Vendita</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <AdvancedSearch :list="pvs" :filter="'code'" :info="'name'" :placeholder="'Punti Vendita'"
                          @changeValue="selectPV" />
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                  </tfoot>
                </table>
              </div>
            </section>
            <!-- STEP 2 -->
            <section class="step step-2" v-show="step === 2">
              <header>
                <h4>
                  Effettua un nuovo ordine
                  <small>
                    #{{(!pastOrders || !pastOrders.length) ? 1 : pastOrders[0].id + 1}}
                    <span v-if="pv" class="text-xs tracking-wide">— P.V. <b class="text-sm">{{pv}}</b>
                      {{getPvName(pv)}}</span>
                  </small>
                </h4>
                <div class="button-group">
                  <button class="behind" @click="goTo(1)">
                    <v-icon name="bi-arrow-left-short" />Indietro
                  </button>
                  <button class="ahead" @click="goTo(3)">
                    Avanti
                    <v-icon name="bi-arrow-right-short" />
                  </button>
                </div>
              </header>
              <div class="table-wrapper overflowed order-table" v-if="!reloadTable">
                <table>
                  <tbody>
                    <div class="row" v-for="(item, index) in items" :key="index">
                      <div class="column">
                        <v-icon v-if="index > 0 && index + 1 === items.length" title="Rimuovi riga"
                          @click="removeEl(index)" class="remove" name="pr-minus-circle" />
                      </div>
                      <div class="column">
                        <!-- search by CODE -->
                        <label>Articolo*</label>
                        <AdvancedSearch :list="extraData.use_only_default_substitute_code ? alternates : prodAlt"
                          :filter="'substitute'" :index="index" :placeholder="'ID articolo'"
                          :overrider="overrider[index].name" @changeValue="addItemByCode" />
                      </div>
                      <div class="column">
                        <label>Descrizione</label>
                        <!-- search by NAME -->
                        <AdvancedSearch :list="extraData.use_only_default_substitute_code ? products : prodAlt"
                          :filter="'item_description'" :index="index" :placeholder="'Nome articolo'"
                          :overrider="overrider[index].code" @changeValue="addItemByName" />
                      </div>
                      <div class="column">
                        <label>Quantità*</label>
                        <input type="number" min="1" required v-model="items[index].qta" @change="validateQta(index)">
                      </div>
                      <div class="column" v-if="shownFields.includes('loyalty_card')">
                        <label>Carta Fedeltà<span v-if="requiredFields.includes('loyalty_card')">*</span></label>
                        <input
                          placeholder="N° Carta"
                          type="text"
                          maxlength="13"
                          @change="eanResult(items[index].card)"
                          v-model="items[index].card"
                          :required="requiredFields.includes('loyalty_card') ? true : false"
                          :disabled="lockedFields.includes('loyalty_card')"
                        >
                      </div>
                      <div class="column">
                        <!-- <input
                          type="checkbox"
                          :id="'shipping-' + index"
                          :name="'shipping-' + index"
                          v-model="items[index].spediz.bool"
                          @change="refreshInput(items[index].spediz.bool, index)"
                        >
                        <label :for="'shipping-' + index">E-mail</label> -->
                        <label v-if="checkGroup(items[index].article)">Email *</label>
                        <input
                          v-if="checkGroup(items[index].article)"
                          type="mail"
                          @blur="validateEmail(index)"
                          v-model="items[index].spediz.email"
                          placeholder="Indirizzo"
                          required
                          :disabled="lockedFields.includes('email')"
                        />
                      </div>
                      <div class="column" v-if="shownFields.includes('customer_firstname')">
                        <label>Nome<span v-if="requiredFields.includes('customer_firstname')">*</span></label>
                        <input placeholder="Nome"
                          type="text"
                          v-model="items[index].firstname"
                          :required="requiredFields.includes('customer_firstname') ? true : false"
                          :disabled="lockedFields.includes('customer_firstname')"
                        >
                      </div>
                      <div class="column" v-if="shownFields.includes('customer_lastname')">
                        <label>Cognome<span v-if="requiredFields.includes('customer_lastname')">*</span></label>
                        <input
                          placeholder="Cognome"
                          type="text"
                          v-model="items[index].lastname"
                          :required="requiredFields.includes('customer_lastname') ? true : false"
                          :disabled="lockedFields.includes('customer_lastname')"
                        >
                      </div>
                      <div class="column" v-if="shownFields.includes('customer_business_name')">
                        <label>Ragione sociale<span
                            v-if="requiredFields.includes('customer_business_name')">*</span></label>
                        <input
                          type="text"
                          v-model="items[index].customer.business"
                          maxlength="50"
                          :required="requiredFields.includes('customer_business_name') ? true : false"
                          :disabled="lockedFields.includes('customer_business_name')"
                        >
                      </div>
                      <div class="column" v-if="shownFields.includes('customer_client_code')">
                        <label>Codice cliente<span
                            v-if="requiredFields.includes('customer_client_code')">*</span></label>
                        <input
                          type="text"
                          v-model="items[index].customer.client"
                          :required="requiredFields.includes('customer_client_code') ? true : false"
                          :disabled="lockedFields.includes('customer_client_code')"
                        >
                      </div>
                      <div class="column" v-if="shownFields.includes('customer_phone_number')">
                        <label>N° Telefono<span v-if="requiredFields.includes('customer_phone_number')">*</span></label>
                        <input
                          type="tel"
                          v-model="items[index].customer.phone"
                          :required="requiredFields.includes('customer_phone_number') ? true : false"
                          :disabled="lockedFields.includes('customer_phone_number')"
                        >
                      </div>
                      <div class="column">
                        <v-icon v-if="index + 1 === items.length && items[index].article" title="Aggiungi riga"
                          @click="addEl(index + 1)" class="add" name="pr-plus-circle" />
                      </div>
                    </div>
                  </tbody>
                  <tfoot>
                  </tfoot>
                </table>
              </div>
            </section>
            <!-- STEP 3 -->
            <section class="step step-3" v-show="step === 3">
              <header>
                <h4>
                  Effettua un nuovo ordine
                  <small>
                    #{{(!pastOrders || !pastOrders.length) ? 1 : pastOrders[0].id + 1}}
                    <span v-if="pv" class="text-xs tracking-wide">— P.V. <b class="text-sm">{{pv}}</b>
                      {{getPvName(pv)}}</span>
                  </small>
                </h4>
                <div class="button-group">
                  <button class="behind" @click="goTo(2)">
                    <v-icon name="bi-arrow-left-short" />Indietro
                  </button>
                </div>
              </header>
              <div class="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>Articolo</th>
                      <th class="px-4">Desc</th>
                      <th>Qta</th>
                      <th v-if="shownFields.includes('loyalty_card')">Carta</th>
                      <th>Spedizione</th>
                      <th
                        v-if="shownFields.includes('customer_firstname') || shownFields.includes('customer_lastname')">
                        Nome & Cognome
                      </th>
                      <th v-if="shownFields.includes('customer_business_name')">
                        Ragione sociale
                      </th>
                      <th v-if="shownFields.includes('customer_client_code')">
                        Codice cliente
                      </th>
                      <th v-if="shownFields.includes('customer_phone_number')">
                        N° Tel.
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                      <td class="resume"><b class="text-primary-blue"
                          v-if="item.article">{{item.article.substitute}}</b></td>
                      <td class="resume">{{getDescription(items[index].article)}}</td>
                      <td class="resume"><b class="text-primary-lblue">{{item.qta}}</b></td>
                      <td v-if="shownFields.includes('loyalty_card')" class="resume">{{item.card}}
                      </td>
                      <td class="resume truncate" :title="getMail(item)">{{ getMail(item) }}</td>
                      <td v-if="shownFields.includes('customer_firstname') || shownFields.includes('customer_lastname')"
                        class="resume">
                        {{item.firstname + ' ' + item.lastname}}
                      </td>
                      <td v-if="shownFields.includes('customer_business_name')" class="resume">
                        {{item.customer.business}}
                      </td>
                      <td v-if="shownFields.includes('customer_client_code')" class="resume">
                        {{item.customer.client}}
                      </td>
                      <td v-if="shownFields.includes('customer_phone_number')" class="resume">
                        {{item.customer.phone}}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>
                        <button type="submit" @click="tryOrder">
                          Conferma l'ordine
                        </button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </section>
            <section class="step step-4" v-show="step === 4">
              <header>
                <h4 v-if="!checkSuccess(orderSuccess)">
                  ⚠Errore—i seguenti ordini non sono stati elaborati correttamente:
                  <small class="mt-4">
                    <span v-for="(success, index) in orderSuccess" :key="index">
                      <span v-if="success.failed">—{{success.item}}</span><br />
                    </span>
                  </small>
                  <span class="block mt-8">
                    <a href="#" @click.prevent="refresh" class="text-primary-orange hover:underline">Riprovare</a>
                    oppure contattare l'assistenza.
                  </span>
                </h4>
                <h4 v-else>
                  Ordine inviato correttamente.
                  <small class="ml-4">
                    <span>
                      Attendi, oppure <a href="#" @click.prevent="refresh"
                        class="text-primary-orange hover:underline">ricarica</a> la pagina.
                    </span>
                  </small>
                </h4>
              </header>
            </section>
            <small class="disclaimer" v-if="step === 2">* Campi obbligatori</small>
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-else>
      LOADING ...
    </div>

    <!-- PAST ORDERS -->
    <div class="divider">
      <div class="container mx-auto px-4">
        <h3>
          Ordini <b>Precedenti</b>
        </h3>
      </div>
    </div>
    <div class="past-filters" v-if="campData">
      <div class="container mx-auto px-4">
        <h6>—Filtri</h6>
        <ul>
          <li class="field">
            <!-- search by ID -->
            <AdvancedSearch v-if="!searchPast.resetPastID && !searchReset"
              :list="extraData.use_only_default_substitute_code ? alternates : prodAlt" :filter="'substitute'"
              :info="'item_description'" :placeholder="'Articolo'" @changeValue="changePastItem" />
          </li>
          <li class="field">
            <!-- search by PV -->
            <AdvancedSearch v-if="!searchPast.resetPastPv && !searchReset" :list="pvs" :filter="'code'" :info="'name'"
              :placeholder="'Punto Vendita'" @changeValue="changePastPv" />
          </li>
          <li class="field">
            <input type="text" v-if="shownFields.includes('loyalty_card')" name="loyalty" id="loyalty" placeholder="Carta Fedeltà" v-model="searchPast.loyalty" />
          </li>
          <li class="field">
            <input type="text" name="search-name" id="search-name" placeholder="Nome / Cognome utente" v-model="searchPast.nameSurname" />
          </li>
          <li class="field">
            <input type="date" name="from" id="date-from" v-model="date.from" />
          </li>
          <li class="field">
            <input type="date" name="to" id="date-to" :min="date.from" v-model="date.to" />
          </li>
        </ul>
        <ul>
          <li><button class="btn reset" @click="filterPastReset">Reset</button></li>
          <li><button class="btn go" @click="filterPast(true)">Cerca</button></li>
        </ul>
      </div>
    </div>
    <section class="past-orders" v-if="!loading"><!-- && !loading2 -->
      <div class="container mx-auto px-4">
        <ul v-if="pastOrders && pastOrders.length">
          <li v-for="(order, index) in pastOrders" :key="index">
            <OrderTeaser :order="order" :desc="getDescription(order, true)" :pvs="pvs" />
          </li>
        </ul>
        <h5 class="error" v-else>Non risultano ordini <b>precedenti</b> al momento.</h5>

        <div class="export-orders" v-if="pastOrders && pastOrders.length">
          <hr />
          <h6>✉ Vuoi esportare gli ordini? (il file verrà inviato all'indirizzo mail dell'utente corrente)</h6>
          <button class="btn btn-orange" @click.prevent="exportOrders">Esporta</button>
        </div>
      </div>
    </section>
    <section class="past-orders text-center" v-if="loading"><!-- || loading2 -->
      <v-icon name="fa-spinner" class="animate-spin text-gray-400 w-20" />
    </section>
    <a class="logout" to="/login" href="#" @click.prevent="logout">
      ‹‹ Logout <b v-if="userData">{{userData.email}}</b>
    </a>
  </div>
</template>

<script>
// import campaigns from '@/json/mock/campaigns.json'
// import orders from '@/json/mock/orders.json'
import { checkEan } from '@/utils/ean13.js'
import * as Sentry from '@sentry/vue'
import AdvancedSearch from '@/components/AdvancedSearch.vue'
import OrderTeaser from '@/components/OrderTeaser.vue'
import moment from 'moment'
moment.locale('it')

export default {
  components: {
    AdvancedSearch,
    OrderTeaser
  },
  data () {
    return {
      loading: true,
      loading2: false,
      isActive: false,
      campData: null,
      reloadTable: false,
      step: 1,
      pv: null,
      items: [],
      pastOrders: [],
      orderSuccess: [],
      overrider: [],
      products: null,
      alternates: null,
      prodAlt: [],
      pvs: null,
      eanCheck: this.shownFields && this.shownFields.includes('loyalty_card') ? false : null,
      prodCodes: null,
      historyCounter: 0,
      historyLimit: 18,
      counter: 0,
      stopFire: false,
      searchReset: false,
      date: {
        from: null,
        to: null
      },
      searchPast: {
        article: null,
        pv: null,
        nameSurname: null,
        resetPastID: false,
        resetPastPv: false
      }
    }
  },
  computed: {
    userData () {
      return this.$store.state.userData
    },
    extraData () {
      return this.$store.state.userData ? JSON.parse(this.$store.state.userData.extra_data) : false
    },
    shownFields () {
      if (this.extraData && this.extraData.shown_fields === undefined) return []
      else return this.extraData.shown_fields
    },
    lockedFields () {
      if (this.extraData && this.extraData.locked_fields === undefined) return []
      else return this.extraData.locked_fields
    },
    requiredFields () {
      if (this.extraData && this.extraData.required_fields === undefined) return []
      else return this.extraData.required_fields
    }
  },
  async mounted () {
    console.log('---CAMPAIGNS', this.$store.state.campaigns)
    console.log('---extra', this.extraData)
    const camp = this.$store.state.campaigns ? this.$store.state.campaigns.filter((el) => {
      return el.id === parseInt(this.$route.params.id)
    }) : null
    this.campData = camp ? camp[0] : null
    console.log('---CURRENT CAMPAIGN', this.campData)

    if (this.campData) {
      this.checkIsActive()
      await this.$store.dispatch('getSingleAgreement', this.campData.agreement_no)
        .then((rsp) => {
          this.products = rsp.filter((el) => {
            return el.item_group === 100 || el.item_group === 101 || el.item_group === 108 || el.properties5 === true
          })
        })
      const payload2 = {
        agreement_no: this.campData.agreement_no,
        card: this.campData.bp_code
      }
      await this.$store.dispatch('getCampaignAlternates', payload2)
        .then((rsp) => {
          const defaults = this.extraData && this.extraData.use_only_default_substitute_code ? this.extraData.use_only_default_substitute_code : false
          // console.log('EXTRA', this.extraData, 'DEFAULTS', defaults)
          if (this.extraData && defaults) {
            this.alternates = rsp.filter((el) => {
              return el.is_default || el.agreement_no === this.campData.agreement_no
            })

            const crossTable = []
            this.alternates = this.alternates.filter((el) => {
              const code = el.item_code

              this.products.filter((el2) => {
                // console.log(el2.item_no, code)
                if (el2.item_no === code) {
                  crossTable.push(code)
                }
              })

              if (crossTable.includes(code)) return el
            })

            // ---IF item_enabled_codes in extra data
            if (this.extraData && this.extraData.item_enabled_codes && this.extraData.item_enabled_codes.length > 0) {
              const tempTable = []
              const enabled = this.extraData.item_enabled_codes

              // ---CHECK IF RSP INCLUDES SOME SUBSTITUTES FROM EXTRADATA
              let tempAlternates = rsp.filter((el3) => {
                return enabled.includes(el3.substitute)
              })

              // console.log('#RSP FILTERED BY ITEM ENABLED', tempAlternates)

              // ---CROSS WITH PRODUCT TABLE
              tempAlternates = tempAlternates.filter((el4) => {
                const code = el4.item_code

                this.products.filter((el5) => {
                  // console.log(el2.item_no, code)
                  if (el5.item_no === code) {
                    tempTable.push(code)
                  }
                })

                if (tempTable.includes(code)) return el4
              })

              // console.log('#THEN, FILTERED BY PRODUCT TABLE', tempAlternates)

              // ---CROSS WITH THE ALREADY FILTERED ALTERNATE CAT NUM
              tempAlternates = tempAlternates.filter((el6) => {
                const sub = el6.substitute
                let found = false

                for (let i = 0; i < this.alternates.length; i++) {
                  if (sub === this.alternates[i].substitute) {
                    found = true
                    break
                  }
                }

                return found === false
              })

              console.log('#THEN, FILTERED BY (ALREADY) FILTERED ALTERNATE', tempAlternates)
              this.alternates = this.alternates.concat(tempAlternates)
            }
          } else {
            this.alternates = rsp
            Sentry.captureException(rsp)
          }
        })

      if (this.extraData.use_only_default_substitute_code) {
        this.alternates.map((el) => {
          this.prodAlt.push(el)
        })
      } else {
        this.products.map((el) => {
          el.description = el.item_description
          el.item_code = el.substitute = el.item_no
          this.prodAlt.push(el)
        })
        this.alternates.map((el) => {
          this.prodAlt.push(el)
        })
      }

      // console.log('PROD ALT', this.prodAlt)
      const payload = {
        bp_code: this.campData.bp_code,
        agreement_no: this.campData.agreement_no
      }
      await this.$store.dispatch('getCampaignPVs', payload)
        .then((rsp) => {
          // console.log('PVS', rsp)
          const kind = this.extraData && this.extraData.pos_enabled_kinds ? this.extraData.pos_enabled_kinds : false
          const enabled = this.extraData && this.extraData.pos_enabled_codes ? this.extraData.pos_enabled_codes : false
          // console.log('EXTRA', this.extraData, 'KIND', kind)
          let finalList = rsp
          if (this.extraData && this.extraData.pos_enabled_kinds) {
            finalList = finalList.filter((el) => {
              return kind.includes(el.kind)
            })
          }
          if (this.extraData && this.extraData.pos_enabled_codes) {
            finalList = finalList.filter((el) => {
              return enabled.includes(el.code)
            })
          }

          this.pvs = finalList
        })

      const data = {
        agreement: this.campData.agreement_no,
        skip: 0,
        limit: this.historyLimit
      }
      await this.$store.dispatch('getPastOrders', data)
        .then((rsp) => {
          this.pastOrders = rsp
        })
    }
    // console.log('PRODS', this.products)
    // console.log('PVS', this.pvs)
    // console.log('ALTERNATES', this.alternates)
    this.addEl()
    setTimeout(() => {
      this.loading = false
    }, 2000)

    window.onscroll = async (ev) => {
      const pastScrolled = (window.innerHeight + window.scrollY) >= document.body.offsetHeight
      const checkPostLimit = this.pastOrders.length >= (this.historyCounter * this.historyLimit)
      if (pastScrolled && checkPostLimit && !this.stopFire) {
        // console.log('PAST', this.pastOrders)
        this.stopFire = true
        // you're at the bottom of the page
        this.historyCounter += 1
        let skip
        let limit
        let data
        if (!this.searchPast.article && !this.searchPast.pv && !this.searchPast.nameSurname && !this.date.from && !this.date.to) {
          skip = this.historyCounter * this.historyLimit
          limit = this.historyLimit
          data = {
            agreement: this.campData.agreement_no,
            skip,
            limit
          }
        } else {
          skip = this.historyCounter * this.historyLimit
          limit = this.historyLimit
          data = {
            agreement: this.campData.agreement_no,
            skip: skip,
            limit: limit
          }
          if (this.searchPast.nameSurname) {
            data.full_name = this.searchPast.nameSurname
          }
          if (this.date.from) {
            data.from = this.date.from
          }
          if (this.date.to) {
            data.to = this.date.to
          }
          if (this.searchPast.pv) {
            data.pdv_id = this.searchPast.pv
          }
          if (this.searchPast.article) {
            data.item_id = this.searchPast.article
          }
          if (this.searchPast.loyalty) {
            data.loyalty_card = this.searchPast.loyalty
          }
        }

        await this.$store.dispatch('getPastOrders', data)
          .then((rsp) => {
            // console.table('RSP', data, rsp)
            this.pastOrders = this.pastOrders.concat(rsp)
            this.stopFire = false
          })
      } else {
        this.stopFire = false
      }
    }
  },
  methods: {
    checkEan,
    checkIsActive () {
      const start = moment(this.campData.start_date)
      const end = moment(this.campData.end_date)
      const today = moment()

      if (today.isBetween(start, end)) {
        this.isActive = true
      }
    },
    async exportOrders () {
      const data = {
        email: this.userData.email,
        agreement: this.campData.agreement_no
      }
      await this.$store.dispatch('exportOrders', data)
        .then((rsp) => {
          if (rsp) {
            const msg = 'Export inviato correttamente: controllare all\'indirizzo ' + this.userData.email
            const error = false
            this.emitter.emit('fireAlert', { msg, error })
          } else {
            const msg = 'Errore durante l\'export: riprovare più tardi.'
            const error = true
            this.emitter.emit('fireAlert', { msg, error })
          }
        })
    },
    changePastItem (val) {
      // this.searchPast.pv = null
      // this.searchPast.resetPastPv = true
      // setTimeout(() => {
      //   this.searchPast.resetPastPv = false
      // }, 100)
      this.searchPast.article = val
    },
    changePastPv (val) {
      // this.searchPast.article = null
      // this.searchPast.resetPastID = true
      // setTimeout(() => {
      //   this.searchPast.resetPastID = false
      // }, 100)
      this.searchPast.pv = val
    },
    async filterPastReset () {
      this.searchPast = {
        article: null,
        pv: null,
        name: null,
        surname: null,
        resetPastID: true,
        resetPastPv: true
      }
      this.date = {
        from: null,
        to: null
      }
      setTimeout(() => {
        this.searchPast.resetPastID = false
        this.searchPast.resetPastPv = false
        this.historyCounter = 0
      }, 100)

      const data = {
        agreement: this.campData.agreement_no,
        skip: 0,
        limit: this.historyLimit
      }
      await this.$store.dispatch('getPastOrders', data)
        .then((rsp) => {
          this.pastOrders = rsp
        })
    },
    async filterPast (reset, stopLoading) {
      if (!stopLoading) this.loading2 = true
      if (reset) this.historyCounter = 0
      // console.log('SEARCH FOR', this.searchPast)
      const skip = this.historyCounter * this.historyLimit
      const limit = this.historyLimit
      const data = {
        agreement: this.campData.agreement_no,
        skip: skip,
        limit: limit
      }
      if (this.searchPast.nameSurname) {
        data.full_name = this.searchPast.nameSurname
      }
      if (this.date.from) {
        data.from = this.date.from
      }
      if (this.date.to) {
        data.to = this.date.to
      }
      if (this.searchPast.pv) {
        data.pdv_id = this.searchPast.pv
      }
      if (this.searchPast.article) {
        data.item_id = this.searchPast.article
      }
      if (this.searchPast.loyalty) {
        data.loyalty_card = this.searchPast.loyalty
      }
      await this.$store.dispatch('getPastOrders', data)
        .then((rsp) => {
          console.log('---UNIFIED search', rsp)
          this.pastOrders = rsp
        })
      this.loading2 = false
    },
    getMail (item) {
      if (item.spediz.email) return item.spediz.email
      else return '—'
    },
    validateQta (index) {
      const target = this.items[index].qta
      if (target < 0) {
        this.items[index].qta = 0
      }
    },
    eanResult (val) {
      this.eanCheck = this.checkEan(val)
    },
    getPvName (pvCode) {
      const target = this.pvs.filter((el) => {
        return el.code === pvCode
      })

      if (target && target[0]) return '(' + target[0].name + ')'
      else return null
    },
    async logout () {
      await this.$store.dispatch('logout')
        .then(() => this.$router.push('/login'))
    },
    selectPV (val) {
      this.pv = val
    },
    addItemByCode (obj) {
      // console.log('ITEM BY CODE', obj)
      this.overrider[obj.index].name = null
      this.items[obj.index].article = obj.val
      const desc = this.getDescription(obj.val)
      this.overrider[obj.index].code = desc
      if (this.checkGroup(this.items[obj.index].article) && this.userData && this.userData.email) this.items[obj.index].spediz.email = this.userData.email
    },
    addItemByName (obj) {
      // console.log('ITEM BY NAME', obj)
      this.overrider[obj.index].code = null
      const code = this.getCodeByDesc(obj.val)
      this.items[obj.index].article = code
      this.overrider[obj.index].name = code.substitute
      if (this.checkGroup(this.items[obj.index].article) && this.userData && this.userData.email) this.items[obj.index].spediz.email = this.userData.email
      // console.log(this.items[obj.index].spediz.email)
    },
    checkGroup (tgt) {
      const list = this.extraData.use_only_default_substitute_code ? this.products : this.prodAlt
      const target = list.filter((el) => {
        if (tgt && tgt.item_code) return el.item_no === tgt.item_code
      })
      if (target && target[0] && (target[0].item_group === 101 || target[0].item_group === 108 || target[0].properties5 === true)) return true
      else return false
    },
    validateEmail (index) {
      const mail = this.items[index].spediz.email
      // eslint-disable-next-line
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail) === false) {
        const msg = 'Indirizzo mail non valido'
        const error = true
        this.emitter.emit('fireAlert', { msg, error })
        this.items[index].spediz.email = null
        return false
      } else {
        const msg = 'Indirizzo mail valido'
        const error = false
        this.emitter.emit('fireAlert', { msg, error })
        return true
      }
    },
    refresh (timer) {
      setTimeout(() => {
        this.$router.go()
      }, timer)
    },
    checkSuccess (success) {
      let temp = true
      success.map((el) => {
        // console.log('CHECKSUCCESS', el)
        if (el && el.failed) temp = false
      })
      return temp
    },
    tryOrder () {
      this.loading = true
      const agreement = this.campData.agreement_no
      this.items.map(async (el) => {
        // console.log('EL', el)
        const data = {
          agreement_no: agreement,
          pdv_id: this.pv,
          item_id: el.article.substitute,
          email: null,
          loyalty_card: null,
          customer_firstname: null,
          customer_lastname: null,
          customer_business_name: null,
          customer_client_code: null,
          customer_phone_number: null,
          quantity: el.qta
        }
        if (el.spediz.email) data.email = el.spediz.email
        if (this.shownFields.includes('loyalty_card')) data.loyalty_card = el.card
        if (this.shownFields.includes('customer_firstname')) data.customer_firstname = el.firstname
        if (this.shownFields.includes('customer_lastname')) data.customer_lastname = el.lastname
        if (this.shownFields.includes('customer_business_name')) data.customer_business_name = el.customer.business
        if (this.shownFields.includes('customer_client_code')) data.customer_client_code = el.customer.client
        if (this.shownFields.includes('customer_phone_number')) data.customer_phone_number = el.customer.phone
        const result = await this.$store.dispatch('submitOrder', data)
        if (result) {
          this.orderSuccess.push(result)
        } else {
          this.orderSuccess.push({
            item: this.getDescription(el.article),
            failed: true
          })
        }
      })
      // console.log('---SUCCESS', this.orderSuccess)
      this.step = 4
      setTimeout(() => {
        this.loading = false
        if (this.checkSuccess(this.orderSuccess)) {
          this.refresh(3000)
        }
      }, 3000)
    },
    getCodeByDesc (desc) {
      const list = this.extraData.use_only_default_substitute_code ? this.alternates : this.prodAlt
      const target = list.filter((el) => {
        // console.log('get code by desc', el)
        return el.item_code === desc.item_no
      })
      if (target && target[0]) return target[0]
    },
    getDescription (tgt, isAlternate) {
      const list = this.extraData.use_only_default_substitute_code ? this.alternates : this.prodAlt
      let alterTarget = null
      if (isAlternate) {
        alterTarget = list.filter((el) => {
          if (tgt && tgt.item_id) return el.substitute === tgt.item_id
        })
      }

      const list2 = this.extraData.use_only_default_substitute_code ? this.products : this.prodAlt
      let target = null
      if (alterTarget) {
        target = list2.filter((el) => {
          if (alterTarget[0] && alterTarget[0].item_code) return el.item_no === alterTarget[0].item_code
        })
      } else {
        target = list2.filter((el) => {
          if (tgt && tgt.item_code) return el.item_no === tgt.item_code
        })
      }
      if (target && target[0]) return target[0].item_description
      else return false
    },
    refreshInput (val, index) {
      if (!val) this.items[index].spediz.email = null
    },
    goTo (index) {
      if (index === 3) {
        let stop = false
        let msg = 'Impossibile elaborare l\'ordine: controlla bene i campi inseriti.'
        const lngth = this.items.length
        for (let i = 0; i < lngth; i++) {
          const el = this.items[i]
          const isCard = this.checkGroup(el.article)
          if (lngth !== 1 && !el.article) this.items.splice(i, 1)
          if (
            (lngth === 1 && !el.article) ||
            !el.qta ||
            (isCard && !el.spediz.email) ||
            (this.eanCheck === false) ||
            (this.requiredFields.includes('loyalty_card') && !el.card) ||
            (this.requiredFields.includes('customer_firstname') && !el.firstname) ||
            (this.requiredFields.includes('customer_lastname') && !el.lastname) ||
            (this.requiredFields.includes('customer_business_name') && !el.customer.business) ||
            (this.requiredFields.includes('customer_client_code') && !el.customer.client) ||
            (this.requiredFields.includes('customer_phone_number') && !el.customer.phone)
          ) stop = true
          if ((this.eanCheck === false && el.card) || (this.shownFields.includes('loyalty_card') && !el.card)) msg = 'Impossibile elaborare l\'ordine: uno o più numeri carta non risultano validi.'
          if (isCard && !el.spediz.email) msg = 'Impossibile elaborare l\'ordine: non hai inserito un indirizzo mail valido.'
        }
        const error = !!stop
        if (stop) this.emitter.emit('fireAlert', { msg, error })
        else this.step = 3
      } else {
        this.step = index
      }
    },
    timeFormatter (time) {
      return moment(time).format('ll')
    },
    addEl (indexPlus) {
      this.items.push(
        {
          article: null,
          desc: null,
          qta: 1,
          card: null,
          firstname: null,
          lastname: null,
          spediz: {
            // bool: false,
            email: null
          },
          customer: {
            business: null,
            client: null,
            phone: null
          }
        }
      )
      this.overrider.push(
        {
          code: null,
          name: null
        }
      )
      // console.log(this.items)
    },
    removeEl (index) {
      // console.log(index)
      this.items.splice(index, 1)
      // this.reloadTable = true
      // setTimeout(() => {
      //   this.reloadTable = false
      // }, 250)
      // const temp = this.items.filter(function (item, id) {
      //   console.log(index, id)
      //   return id !== index
      // })

      // this.items = temp
    }
  }
}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/views/campaign.scss";
</style>
